#hdr{
    .hdrTop{
        background:#EFEFEF;
        color: #303030;
        .hdrInfo{
            @media (max-width: 1199px){
                text-align: center;
            }
        }
        &.spOnly{
            background:#fff;
            .hdrInfo{
                color:#893B3B;
            }
            border-bottom: 1px solid #EFEFEF;
        }
    }
    .hdrTop p, .hdrTop a, .hdrTop a:hover{
        color: #303030;
    }
    .hdrTop a::after{
        border-color: #303030!important;
    }
    .btnNav div{
        color:#893B3B;
        border-color: #E1D7CF;
        font-size: 10px;
        strong{
            font-size: 14px;
            margin-right: 5px;
        }
        i{
            color:#893B3B;
        }
    }
    .iconNav__cart .cartAmount{
        background:#EFEFEF;
        color:#303030;
        right: 1px;
    }
    .hdr--typeE .hdrBottom .hdrInner{
        padding: 10px 0;
    }
    .searchForm.searchFormB .searchForm__inner{
        border:none;
        border-bottom: 1px solid #dfdfdf;
        border-radius: 0;
    }
    .gNav {
        li.hasChildren > .megaMenu{
            h3{
                font-weight: $head_font_weight;
                text-align: center;
                font-size: 16px;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin:auto;
                    display: block;
                    background: $color_border;
                    width: 200px;
                    height: 1px;
                }
            }
            &.small-width{
                .megaMenu__inner{
                    max-width: 910px;
                }
            }
        }
    }
}
.drawer__accountText h2{
    font-family: $font_family_mincho;
}
.drawer__itemList h3{
    font-family: $font_family_mincho;
}
.slick-dots li button:before{
    background: #fff;
    border: 1px solid #303030;
}
.newsList {
    .newsList__head h2{
        background:#FAFAFA;
        color:#303030;
    }
    li h3{
        font-weight: normal;
    }
}
.sec-item{
    .itemArrange__img{
        border: 1px solid #DFDFDF;
        margin-bottom: 0;
    }
    .itemArrange__text{
        border: 1px solid #DFDFDF;
        margin-top:-1px;
        padding: 40px;
        display: block;
        h3{
            font-family: $font_family_mincho;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 5px;
        }
        @media screen and (max-width: 767px){
            padding: 6px;
            p{
                font-size: 10px;
                line-height: 15px;
            }
            h3{
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 2px;
            }
        }
    }
}
.sec-seller {
    background-color: #F7F6F3;
    .itemArrange__img{
        margin-bottom: 0;
    }
    .itemArrange__text {
        padding: 20px;
        h3{
            font-family: $font_family_mincho;
            font-size: 18px;
            margin-bottom: 15px;
        }
    }
}
.sec-keyword{
    padding: 20px 0;
    margin-bottom: 40px;
    .keywordList li a{
        background:#FFF;
    }
    @media screen and (max-width: 767px){
        padding: 10px 0;
        .ks__keywords{
            padding: 0;
        }
    }    
}
.sec-cateButton{
    .row{
        justify-content: center;
    }
    a{
        font-family: $font_family_mincho;
        font-weight: 500;
    }
}
.sec-concept{
    h2{
        margin-bottom: 20px;
        font-family: $font_family_mincho;
        @media screen and (max-width: 767px){
            margin-bottom: 10px;
        }
    }
    .h-img{
        text-align: center;
        margin-bottom: 20px;
    }
    p.text--center{
        line-height: 28px;
    }
}
.sec-categry{
    background:#fff;
    border-top: 1px solid #C2C2C2;
    padding-bottom: 0;
    .container{
        max-width: 910px;
    }
}
[class*="iconButton"], a[class*="iconButton"]{
    font-weight: 400!important;
}
.tab--typeB {
    .tabSwitch {
        li{
            font-weight: 400;
            &.active{
                font-weight: 400;
                border-bottom: 2px solid $color_secondary;
            }
            &:last-child{
                &::after{
                    content: none;
                }
            }
        }
    }
}
.itemList--ranking.ranking--typeD .itemList__unit .itemWrap:before{
    border-color: #303030;
}
.sec-ranking{
    .itemList--ranking.ranking--typeD .itemList__unit{
        @media screen and (max-width: 767px){
            &:last-child(n + 7){
                display: none;
            }
        }
        .itemWrap:before{
            border-width: 1px;
            border-style: solid;
            border-radius: 50%;
            font-family: $heading_font_family;
            font-size: 26px;
            font-weight: 700;
            line-height: 26px;
         }
    }
}

.sec-scene{
    background: #FAFAFA;
    .container{
        max-width: 910px;
    }
    .iconButton--squareNoImg{
        padding: 15px 30px 15px 20px!important;
    }
}

.sec-group{
    .group-box{
        border: 1px solid #CDA440;
        padding: 50px;
        width: 100%;
        max-width: 1000px;
        margin:0 auto;
        @media screen and (max-width: 767px){
            padding: 40px 15px;
        }
    }
    text-align: center;
    img{
        margin-bottom: 20px;
    }
    p{
        margin-bottom: 40px;
    }
}
.drawer__free{
    padding: 15px;
}
.button--typeB{
    font-family: $font_family_mincho;
    font-weight: 500;    
}
.sec-feature{
    .feature-box{
        display: flex;
        img{
            max-width: 135px;
            @media screen and (max-width: 767px){
                max-width: 120px;
            }
        }
        .feature-textarea{
            background:#F7F6F3;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap:10px;
            flex: 1;
            h3{
                font-family: $font_family_mincho;
                line-height: 22px;
                margin-bottom: 10px;
            }
            .feature-text{
                margin-bottom: 0;
            }
            .feature-bottom{
                display: flex;
                justify-content: space-between;
                .arrow{
                    &::after{
                        right: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px){
        .row > div:last-child{
            display: none;
        }
    }
}
.sec-newsList{
    background: #fff;
    border-top: 1px solid #C2C2C2;
    padding-bottom: 0;
    ul{
        list-style: none;
        padding: 0;
        margin: 0 auto;
        max-width: 715px;
        li{
            display: flex;
            gap: 30px;
            margin-bottom: 15px;
            line-height: 22px;
            h3{
                font-weight: 400;
            }
            @media screen and (max-width: 767px){
                flex-direction: column;
                gap:10px;
                border-bottom: 1px solid #DFDFDF;
                padding-bottom: 10px;
            }
        }
    }
}
.userGuide{
    background:#F7F6F3;
    .h2A{
        font-family: $font_family_mincho;
        margin-bottom: 40px;
        @media screen and (max-width: 767px){
            font-size: 20px;
            line-height: 30px;
        }
    }
    .row{
        max-width: 1000px;
        margin: 0 auto;
        text-align: center;
        gap: 20px 0;
        > div{
            border-bottom: 1px solid #C2C2C2;
            position: relative;
            padding-bottom: 20px;
            &::after{
                content: "";
                background:#C2C2C2;
                width: 1px;
                height: calc(100% - 20px);
                right: 0;
                top:0;
                position: absolute;
            }
            @media screen and (min-width: 768px){
                &:nth-child(3n)::after{
                    content: none;
                }
                &:nth-child(n+4){
                    border-bottom: none;
                }
            }
            @media screen and (max-width: 767px){
                &:nth-child(2n)::after{
                    content: none;
                }
                &:nth-child(n+5){
                    border-bottom: none;
                }
            }
        }
        img{
            margin-bottom: 10px;
        }
    }
}
#ftr{
    .fNav--typeA .fNav__list h3{
        font-family: $font_family_mincho;
        border-color: #CDA440;
    }
}

@media screen and (max-width: 992px){
    .tabSwitch.scrollX{
        justify-content: center;
        -webkit-box-pack: center;
    }
}
@media screen and (max-width: 767px){
    .sec-item .itemArrange [class*=col]{
        margin-bottom: 0;
    }
    .sec-recommend .itemList li:nth-child(n + 7){
        display: none;
    }
}
#toTop.toTop--primary a{
    background: #EFEFEF;
    &::after{
        border-color: #1B1B1B;
    }
}